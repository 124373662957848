






import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

@Component({
  components: {
  },
})
export default class Banner extends Vue {

  @Prop(String)
  imgH5: string

  @Prop(String)
  imgPc: string

  @Prop(String)
  alt: string

  get isH5() {
    return AppModule.isH5
  }
}
