



















































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

@Component
export default class QuestionBlock extends Vue {
  get isH5() {
    return AppModule.isH5
  }
}
