















import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'
import FooterBlock from '@/components/FooterBlock.vue'
import ContactBlock from '@/components/ContactBlock.vue'
import QuestionBlock from '@/components/QuestionBlock.vue'

import Banner from './components/Banner.vue'
import HelpBlock from '@/components/HelpBlock.vue'

import { scrollToElem } from '@/utils/scroll'

@Component({
  components: {
    Banner,
    FooterBlock,
    ContactBlock,
    QuestionBlock,
    HelpBlock
  },
})
export default class Program extends Vue {
  showContact = true

  bannerMap = {
    'snacks': {
      imgH5: require('@/assets/program/奶茶-H5.png'),
      imgPc: require('@/assets/program/奶茶-PC.png'),
      alt: '飲品店/咖啡店/快餐店/外賣店',
    },
    'pasta': {
      imgH5: require('@/assets/program/車仔麵-H5.png'),
      imgPc: require('@/assets/program/車仔麵-PC.png'),
      alt: '車仔麵店/米線店/中式粉麵店',
    },
    'restaurant': {
      imgH5: require('@/assets/program/茶餐廳-H5.png'),
      imgPc: require('@/assets/program/茶餐廳-PC.png'),
      alt: '茶餐廳/冰室/西餐廳/酒樓/中菜館',
    },
    'foodcourt': {
      imgH5: require('@/assets/program/Foodcourt-H5.png'),
      imgPc: require('@/assets/program/Foodcourt-PC.png'),
      alt: 'Foodcourt',
    },
    'takeaway': {
      imgH5: require('@/assets/program/中央廚房-H5.png'),
      imgPc: require('@/assets/program/中央廚房-PC.png'),
      alt: '中央廚房+外賣自取點/外賣送餐',
    },
  }

  get bannerData() {
    return this.bannerMap[this.$route.name]
  }

  get isH5() {
    return AppModule.isH5
  }

  contactHeight = 0
  footerHeight = 0
  screenHeight = 0
  bodyHeight = 0

  mounted() {
    this.contactHeight = (this.$refs.contact as any).$el.offsetHeight
    this.footerHeight = (this.$refs.footer as any).$el.offsetHeight
    this.screenHeight = document.documentElement.clientHeight
    window.addEventListener('scroll', this.scrollHandle)
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandle)
  }

  scrollHandle() {
    const scrollTop = document.documentElement.scrollTop
    if (!this.bodyHeight) {
      this.bodyHeight = document.body.offsetHeight
    }
    if (scrollTop >= this.bodyHeight - this.screenHeight - this.footerHeight - this.contactHeight) {
      this.showContact = false
    } else {
      this.showContact = true
    }
  }

  scrollToContact() {
    scrollToElem('#contact', 500, 80)
  }
}
